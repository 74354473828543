import { QuestionAnswerTwoTone } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useForm } from '../../../hooks';

const Epal = ({setMainForm,mainForm }) => {

  const { values: epalForm, changeHandler } = useForm(
    {
      Epal: mainForm.Epal,
    },
    []
  );

  useEffect(() => {
    setMainForm({
      ...mainForm,
      Epal: epalForm.Epal,
    });
  }, [epalForm]);
  
  return (
    <Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
      <CardHeader
        title={"Richiesta di epal a rendere"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize:18
        }}
        avatar={<QuestionAnswerTwoTone />}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              label="Epal"
              type="number"
              autoComplete="off"
              onWheel={(e) => e.target.blur()}
              InputProps={{
                inputProps: { min: 0 }
              }}
              value={epalForm.Epal || ""}
              onChange={(e) => changeHandler("Epal", e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Epal