import { DateRangeRounded } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Grid, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useForm } from '../../../hooks';

const DataRichiesta = ({caption, setMainForm, mainForm}) => {
  const { values: dataRichiestaForm, changeHandler } = useForm(
    {
      DataRichiesta: mainForm.DataRichiesta,
    },
    []
  );

  useEffect(() => {
    setMainForm({
      ...mainForm,
      DataRichiesta: dataRichiestaForm.DataRichiesta,
    });
  }, [dataRichiestaForm]);

  return (
    <Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
    <CardHeader
      title={caption}
      subheader={""}
      subheaderTypographyProps={{
        fontWeight: "bold",
      }}
      titleTypographyProps={{
        fontWeight: "bold",
        fontSize:18
      }}
      avatar={<DateRangeRounded />}
    />
    <CardContent>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
            <Stack spacing={2} direction={"row"}>
              <DatePicker
                label={"Data"}
                value={dataRichiestaForm.DataRichiesta || null}
                onChange={(e, newValue) => {
                  changeHandler("DataRichiesta", moment(e).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Stack>
          </Grid>
      </Grid>
    </CardContent>
  </Card>
  )
}

export default DataRichiesta